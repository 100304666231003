<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="doFilter">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field label="Texto" v-model="search"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  :items="tiposmotivo"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo de Motivo"
                  v-model="tipomotivo"
                ></v-select>
              </v-col>
              <v-col cols="10" md="2">
                <v-select
                  :items="estados"
                  item-text="nombre"
                  item-value="id"
                  label="Estado"
                  value="1"
                  v-model="estado"
                ></v-select>
              </v-col>
              <v-col cols="2" md="1" class="col-btn-filter">
                <v-btn
                  icon
                  type="submit"
                  color="secondary"
                  dark
                  @click="doFilter(true)"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "NovedadesMotivosFilter",
  data() {
    return {
      motivos: [],
      motivo: 0,
      tipomotivo: 0,
      tiposmotivo: [],
      search: "",
      estados: [
        { id: "1", nombre: "Activos" },
        { id: "0", nombre: "Inactivos" },
        { id: "2", nombre: "Todos" },
      ],
      estado: "1",
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    PresentismoService.getNovedadesTipos()
      .then((response) => {
        this.tiposmotivo = response.data;
        this.tiposmotivo.push({ id: 0, nombre: "Todos" });
        this.doFilter();
      })
      .catch((error) => console.log(error));
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;

      this.loading = true;
      PresentismoService.getNovedadesMotivos(
        this.tipomotivo,
        this.search,
        this.estado
      )
        .then((response) => {
          this.motivos = response.data;
          this.$emit("motivos-changed", this.motivos);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      //console.log("haciendo filter con page " + this.pageNumber);
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>

<style>
.col-btn-filter {
  padding-top: 25px;
}
</style>
